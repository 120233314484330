import * as React from 'react';
import {debounce} from 'lodash';

import {ISelectOption, Select} from '@pluto-tv/assemble';
import {useLazySearchByNameQuery} from 'features/carouselConfigs/carouselConfigsApi';
import useSearchCarouselData from 'components/hooks/carousels/useServiceSearchCarousel';
import {TSortDirection} from 'models/generic';

const INIT_PARAMS = {
  sortBy: 'name',
  sortDirection: 'dsc' as TSortDirection,
  limit: 250,
  offset: 0,
};

interface ICarouselConfigAutocompleteProps {
  onSelect: (value?: ISelectOption) => void;
  activeRegion: string;
  disabledIds?: string[];
}

const CarouselConfigAutocomplete = ({
  onSelect,
  activeRegion,
  disabledIds,
}: ICarouselConfigAutocompleteProps): React.ReactElement => {
  const {search: searchCarousel} = useSearchCarouselData(useLazySearchByNameQuery as any);
  const [carouselValue, setCarouselValue] = React.useState<ISelectOption | null>(null);

  const handleChange = (value?: ISelectOption) => {
    setCarouselValue(value || null);
    onSelect(value);
  };

  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (search: string, resolve: (options: ISelectOption[]) => void) => {
        if (!activeRegion || !search) {
          resolve([]);
          return;
        }

        const regions = activeRegion === 'ALL' ? [activeRegion] : [activeRegion, 'ALL'];

        try {
          const response = await searchCarousel({...INIT_PARAMS, name: search, region: regions});

          if (!response.totalCount || !response.data.length) {
            resolve([
              {
                label: 'No Results Found',
                value: '',
                disabled: true,
              },
            ]);
            return;
          }

          resolve(
            response.data.map(config => ({
              label: `${config.name}${config.activeRegion ? ' (' + config.activeRegion[0].toUpperCase() + ')' : ''}`,
              value: config.id,
              disabled: disabledIds?.includes(config.id),
              apiService: config.apis[0].apiService || '',
            })),
          );
        } catch (error) {
          resolve([
            {
              label: 'No Results Found',
              value: '',
              disabled: true,
            },
          ]);
        }
      }, 300),
    [searchCarousel, activeRegion, disabledIds],
  );

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearch = async (search: string): Promise<ISelectOption[]> => {
    return new Promise(resolve => debouncedSearch(search, resolve));
  };

  return (
    <Select
      clearable={true}
      predicate='value'
      autoCompleteTimeout={0}
      placeholder='Search for carousel'
      searchPlaceholder='Search for carousel'
      searchable={true}
      sortField='label'
      value={{label: carouselValue?.label || '', value: carouselValue?.value || ''}}
      onSearch={handleSearch}
      options={[]}
      autoComplete={true}
      onChange={handleChange}
      id='carouselConfig'
    />
  );
};

CarouselConfigAutocomplete.displayName = 'CarouselConfigAutocomplete';
export default CarouselConfigAutocomplete;
